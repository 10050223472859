<template>
  <section class="time-line" v-if="!isApprover">
    <a-timeline>
      <template v-for="(item, index) in approvalList" :key="index">
        <a-timeline-item
          color="red"
          :style="isAdd ? '' : 'height:72px'"
          :class="index + 1 == approvalList.length ? 'time-line-item1' : ''"
        >
          <template #dot>
            <section class="time-sec">
              <div class="time-info">
                <div class="time-avatar">
                  <img
                    class="avatar"
                    :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
                  />
                  <i class="iconfont icon-cha1 remove-arrow" v-if="isAdd" @click="$emit('delete-approval', index)" />
                </div>
              </div>
            </section>
          </template>
          <div class="time-sec">
            <p class="time-name" :title="item.name || item.staffName">{{ item.name || item.staffName }}</p>
          </div>
        </a-timeline-item>
      </template>
      <a-timeline-item v-if="isAdd" color="red" key="ss">
        <template #dot>
          <div class="time-progess" @click="$emit('open-approval-modal')">+</div>
        </template>
      </a-timeline-item>
    </a-timeline>
  </section>
  <!-- 补盖审批人 -->
  <div class="approver-area" v-if="isApprover">
    <div class="info" v-for="(item, index) in approvalList" :key="index">
      <!-- 头像 -->
      <div class="avatar-area">
        <img class="avatar" :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')" />
        <i class="iconfont icon-cha1 remove-arrow" v-if="isAdd && isEdit" @click="$emit('delete-approval', index)" />
      </div>
      <!-- 审批人 -->
      <p class="approver" :title="item.name || item.staffName">{{ item.name || item.staffName }}</p>
    </div>
    <!-- 添加按钮 -->
    <div class="time-progess" @click="$emit('open-approval-modal')" v-if="isEdit">+</div>
  </div>
</template>

<script>
import { approvalStatusObj, archiveStatusObj } from '@/utils/constData'
export default {
  name: 'staff-process',
  props: {
    approvalList: {
      type: Array,
      default: () => []
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    isApprover: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      approvalStatusObj,
      archiveStatusObj
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0; //覆盖公共样式
}
.remove-arrow {
  position: absolute;
  top: 0px;
  right: 1px;
  color: #c3161c;
  font-size: 12px;
  background: #ffffff;
  height: 12px;
  line-height: 12px;
  border-radius: 50%;
  cursor: pointer;
}
:deep(.ant-timeline-item) {
  padding-bottom: 48px;
}
:deep(.ant-timeline-item-content) {
  margin-left: 56px;
  top: 0;
  line-height: 40px;
}
.time-line {
  max-height: 224px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    background: #f5f5f5;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #c6c6c6;
    border-radius: 4px;
  }
}
.time-progess {
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e9e9e9;
  font-size: 18px;
  padding: 0;
  color: #666666;
  cursor: pointer;
}
.time-sec {
  // margin-bottom: 10px;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 40px;
  .time-info {
    display: flex;
    align-items: center;
    .time-avatar {
      margin-right: 16px;
      position: relative;
      width: 40px;
      height: 40px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0; //覆盖公共样式
      }
      .remove-arrow {
        position: absolute;
        top: 0px;
        right: 1px;
        color: #c3161c;
        font-size: 12px;
        background: #ffffff;
        height: 12px;
        line-height: 12px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .time-name {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 40px;
    justify-content: flex-start;
  }
  .remove-arrow {
    color: #cccccc;
    font-size: 14px;
    cursor: pointer;
  }
}
:deep(.ant-timeline-item-tail) {
  border-left: 1px solid #c3161c;
  top: 48px;
  left: 20px;
  height: 40px;
}
:deep(.ant-timeline-item-tail:nth-last-of-type(1)) {
  border-left: 2px solid #e9e9e9;
}
.ant-timeline-item-last {
  padding-bottom: 0;
  > .ant-timeline-item-tail {
    border-left: none;
  }
}
:deep(.ant-timeline-item-head-custom) {
  padding: 0;
  transform: translate(0, 0);
  left: 0;
  top: 0;
  margin: 4px 0;
}
.time-line-item1 {
  :deep(.ant-timeline-item-tail) {
    border-color: #e9e9e9;
  }
}
</style>
<style lang="less" scoped>
.approver-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 468px;
  .info {
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    .avatar-area {
      margin-right: 16px;
      position: relative;
      width: 40px;
      height: 40px;
      .avatar {
      }
      .remove-arrow {
      }
    }
    .approver {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      max-width: 11em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .time-progess {
    margin-bottom: 12px;
  }
}
</style>
